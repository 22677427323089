<template>
  <div>

    <div class="card mb-3 " style="width: 100%" v-loading="loading" element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Payment Integrations</h3>
      </div>
      <el-row :gutter="12">
        <el-col :span="6" v-for="(payment, index) in getActivePaymentSystems" :key="index">
          <span @click="paymentInit(payment)" v-if="payment.status">
            <el-card class="box-card ml-1 mt-1 cardInfo">
              <div class="cardInfoDesigns">
                <img class="image-fit" :src="payment.logo" :alt="payment.name" />
              </div>
            </el-card>
          </span>
          <span v-else>
            <el-card class="box-card ml-1 mt-1 cardInfoBlock">
              <div class="cardInfoDesigns">
                <img class="image-fit" :src="payment.logo" :alt="payment.name" />
              </div>
            </el-card>
          </span>
        </el-col>
      </el-row>
      <div class="clearfix"></div>
    </div>
    <el-dialog :title="`${paymentInfo.name} Payment Gateway Details`" width="50%" top="5vh"
      :visible.sync="stripeApiDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" label-width="120px"
        class="demo-ruleForm">

        <el-form-item label="Alias Name" prop="accountName">
          <el-input v-model="ruleForm.accountName"></el-input>
        </el-form-item>
        <el-form-item label="API Key" prop="apiKey">
          <el-input v-model="ruleForm.apiKey"></el-input>
        </el-form-item>
        <el-form-item label="Public Key" prop="publicKey">
          <el-input v-model="ruleForm.publicKey"></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="ruleForm.isDefault">Make it default payment system</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">Integrate</el-button>
          <el-button @click="resetForm('ruleForm')">Reset</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SubscriptionInformation",
  components: {},
  data() {
    return {
      loading: false,
      individualPlan: {},
      businessPlan: {},
      businessProfessional: {},
      businessEssential: {},
      stripeApiDialog: false,
      paymentInfo: {},
      ruleForm: {
        stripeKey: '',
        apiKey: "",
        publicKey: "",
        isDefault: false,
        accountName: ""

      },
      rules: {
        accountName: [{ required: true, message: 'Please enter Alias name', trigger: 'blur' },],
        stripeKey: [
          { required: true, message: 'Please enter stripe api key', trigger: 'blur' },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
        apiKey: [
          { required: true, message: 'Please enter api key', trigger: 'blur' },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
        publicKey: [
          { required: true, message: 'Please enter public key', trigger: 'blur' },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
      }
    };
  },
  computed: {
    ...mapGetters("paymentGateway", ["getPlansData", "getActivePaymentSystems", "getPaymentGatewayInfo", "getUpdateSystemInfo"]),
    ...mapGetters("auth", ["getActiveWorkspace", "getAuthenticatedUser"]),
  },
  created() { },
  mounted() {
    this.getActivePaymentSystemsApi();

  },
  methods: {
    async getActivePaymentSystemsApi() {
      this.loading = true;
      await this.$store.dispatch("paymentGateway/fetchActivePaymentSystems");
      console.log("active systems", this.getActivePaymentSystems);
      this.loading = false;
    },
    async paymentInit(info) {
      console.log("Payment icons clicked", info);

      this.loading = true;
      await this.$store.dispatch("paymentGateway/fetchPaymentInit", { email: this.getAuthenticatedUser.email, client_id: this.getActiveWorkspace.company_id });
      // console.log("getPaymentGatewayInfo", this.getPaymentGatewayInfo);
      if (this.getPaymentGatewayInfo && this.getPaymentGatewayInfo.ivKey) {
        this.stripeApiDialog = true
        this.paymentInfo = info

      }
      this.loading = false;
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const keys = this.$CryptoJS.AES.encrypt(JSON.stringify(this.ruleForm), this.getPaymentGatewayInfo.ivKey).toString();
          let requestInfo = {
            pgType: this.paymentInfo.code,
            type: "APP",
            status: "ACTIVE",
            crypto: keys,
            accountName: this.ruleForm.accountName,
            isDefault: this.ruleForm.isDefault
          }
          const data = this.$CryptoJS.AES.encrypt(JSON.stringify(requestInfo), this.getPaymentGatewayInfo.ivKey).toString();
          requestInfo.pg_account_id = this.getPaymentGatewayInfo._id;
          requestInfo.ivKey = this.getPaymentGatewayInfo.ivKey;
          // requestInfo.accountName= this.ruleForm.accountName;

          this.loading = true;
          await this.$store.dispatch("paymentGateway/updateSystemInfoById", { info: requestInfo, accountId: this.getPaymentGatewayInfo._id, data: data });

          if (this.getUpdateSystemInfo) {
            this.$notify({
              title: "Success",
              message: `${this.paymentInfo.name} integrated.`, 
              type: "success",
            });
            this.$router.push({ path: "/configuration/paymentSystems" })
          }
          this.stripeApiDialog = false
          this.paymentInfo = {};
          this.ruleForm = {
            stripeKey: '',
            apiKey: "",
            publicKey: "",
            isDefault: false,
            accountName: ""

          };
          this.loading = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }


  }

};
</script>
<style lang="scss" scoped>
.cropper {
  height: 600px;
  width: 600px;
  background: #ddd;
}

.paymentSty {
  cursor: pointer
}

.image-fit {
  //width:68%;
  //height: 100%;
  height:1.3rem;
}

.clearfix {
  padding-bottom: 2rem;
}

.cardInfo {
  cursor: pointer;
  border: 1px solid #80808094;
}

.cardInfoBlock {
  cursor: no-drop;
  border: 1px solid #80808094;
}
.el-card__body {
  padding: 1rem !important;
}

.cardInfoDesigns {
  width: 100%;
  height: 2rem;
  text-align: center;
}</style>